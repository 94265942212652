import React from "react";
import queryString from "query-string";
import useGetAcknowledgedContent from "hooks/useGetAcknowledgedContent";
import Flex from "app/components/Flex";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { useLocation } from "react-router-dom";
import { H1 } from "app/components/Typography";
import { UploadUserVideoModal } from "app/components/UploadUserVideoModal";
import TermsAndConditionsDialog from "./TermsAndConditionsDialog";
import { UserVideoList } from "./UserVideoList";

export default () => {
  const location = useLocation();
  const [{ progress: userProgress }, loading] = useGetAcknowledgedContent();

  const { showCommunityGuidelines } = queryString.parse(location.search);
  const acknowledged =
    typeof userProgress?.acknowledgedContent?.challenges?.[
      "terms-and-conditions"
    ] === "undefined";
  const showTermsAndConditionsDialog =
    acknowledged || !!showCommunityGuidelines;

  if (loading) {
    return <LoaderCentered />;
  }

  return (
    <>
      <Flex
        flexDirection="column"
        height="100%"
        width="100%"
        margin="0 auto"
        p="24px"
        pb="75px"
        maxWidth="1600px"
      >
        <Flex
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          mb="16px"
        >
          <H1>Community</H1>
          <UploadUserVideoModal buttonProps={{ width: "200px" }} />
        </Flex>
        <Flex width="100%" height="100%">
          <UserVideoList />
        </Flex>
      </Flex>
      <TermsAndConditionsDialog
        showAgreeButton={!showCommunityGuidelines}
        isOpen={showTermsAndConditionsDialog}
      />
    </>
  );
};
